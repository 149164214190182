import type { StyleRule } from '@archipro-design/aria';
import { buttonClassName, labelClassName } from '@archipro-design/aria';
import { flexClassName } from '@archipro-design/aria';
import {
    mobileHeaderSectionClassName,
    mobileHeaderSectionSlotClassNames,
} from '@archipro-design/aria';
import { SubheaderClassName, pxArrayToRem } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

const PAGE_HEADER_INNER_HEIGHT_DEFAULT = 100;
const PAGE_HEADER_INNER_GAP = 20;

export const HeaderContainer: StyleRule = ({ theme }) => ({
    [theme.screen.laptop]: {
        padding: pxArrayToRem([130, 0, 90, 0]),
        minHeight: `${pxToRem(410)}`,
        gridGap: `${pxToRem(40)}`,
        gridTemplateRows: `${pxToRem(22)} 1fr`,
    },
});

export const SubHeadingMobile: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(10),
    fontSize: theme.siteVariables.fontSizes.caption04,
    lineHeight: theme.siteVariables.fontSizes.caption04,
    textAlign: 'center',
    color: theme.siteVariables.colors.charcoal['100'],
    '& > a': {
        color: 'inherit',
        textDecoration: 'none',
    },
});

export const Heading: StyleRule = ({ theme }) => ({
    margin: `0`,
    maxWidth: pxToRem(1250),
    color: theme.siteVariables.colors.charcoal['000'],
    ...theme.siteVariables.textStyles.Heading.Bold75,
    textAlign: 'center',
    '& sup': {
        fontSize: '50%',
        top: pxToRem(-1),
        verticalAlign: 'super',
    },

    [theme.screen.min.tablet]: {
        '& sup': {
            top: pxToRem(-4),
        },
    },
});

export const MobileTitleSection: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([0, 18, 0, 18]),
    [`& .${SubheaderClassName}`]: {
        letterSpacing: 0,
        fontSize: theme.siteVariables.fontSizes.label01,
        lineHeight: 1,
    },
});

export const MobileHeader: StyleRule = ({ theme }) => ({
    [`& .${mobileHeaderSectionClassName}`]: {
        minHeight: pxToRem(68),
        height: 'fit-content',

        [`& > div`]: {
            background: 'transparent',
            position: 'relative',
            padding: pxArrayToRem([0, 18]),
        },
        [`& > div > .${flexClassName}`]: {
            width: pxToRem(338),
            margin: 0,
            height: 'unset',
            minHeight: pxToRem(68),
            [`& > .${flexClassName}`]: {
                minHeight: pxToRem(68),
                margin: pxArrayToRem([-17, 0, 0, 0]),
                justifyContent: 'flex-end',
            },
        },
        [`& .${mobileHeaderSectionSlotClassNames.subtitle}`]: {
            color: theme.siteVariables.colorScheme.default?.['foreground5'],
            marginBottom: pxToRem(7),
        },
    },
});

export const MobileHeaderTitle: StyleRule = () => ({
    textAlign: 'center',
});

export const PageHeader: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([28, 18, 0, 18]),

    [theme.screen.tablet]: {
        padding: 0,
        display: 'grid',
        alignItems: 'end',
    },
});

export const PageHeaderInner: StyleRule = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    [theme.screen.tablet]: {
        display: 'grid',
        gridTemplateColumns: `${pxToRem(924)} 1fr`,
        alignItems: 'flex-end',
        minHeight: pxToRem(PAGE_HEADER_INNER_HEIGHT_DEFAULT),
        padding: pxArrayToRem([PAGE_HEADER_INNER_GAP, 50]),
        background: theme.siteVariables.colorScheme.default?.background,
    },

    [theme.screen.clamped]: {
        padding: pxArrayToRem([PAGE_HEADER_INNER_GAP, 0]),
    },
});

export const LeftPane: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
    },
});

export const ProfessionalTitle: StyleRule = ({ theme }) => ({
    marginBottom: pxToRem(0),
    marginTop: pxToRem(0),

    '& a': {
        color: theme.siteVariables.colorScheme.default?.foreground,
        ':hover': {
            color: theme.siteVariables.colorScheme.default?.foregroundHover,
        },
    },

    [theme.screen.laptop]: {
        fontSize: pxToRem(16),
        lineHeight: theme.siteVariables.lineHeightSmaller,
        textDecoration: 'underline',
        display: 'inline',
    },
});

export const RightPane: StyleRule<{
    isSticky: boolean;
}> = ({ theme }) => ({
    marginTop: theme.siteVariables.spaces['space-16'],
    [theme.screen.tablet]: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        position: 'relative',
        margin: 0,
        paddingLeft: pxToRem(18),
    },
});

export const ProjectTitle: StyleRule = ({ theme }) => ({
    maxWidth: `calc(100% - ${pxToRem(64)})`,
    ['&.' + SubheaderClassName]: {
        letterSpacing: 'normal',
    },
    [theme.screen.tablet]: {
        color: theme.siteVariables.colorScheme.default!.foreground,
        maxWidth: 'unset',
        margin: 0,
        fontSize: pxToRem(22),
        lineHeight: 1,
        paddingRight: '1ch',
    },
});

export const DisableHoverBlock: StyleRule = ({ theme }) => ({
    position: 'absolute',
    zIndex: theme.siteVariables.zIndexes.foreground,
    height: '100%',
    width: '100%',
});

export const BreadCrumb: StyleRule = ({ theme }) => ({
    '& .ui-breadcrumbItem': {
        color: theme.siteVariables.colorScheme.default!.foreground,
    },
});

export const ProjectTitleContainer: StyleRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(9),
    justifyContent: 'flex-end',
    paddingBottom: pxToRem(4),
});

export const RightPaneInner: StyleRule = () => ({
    display: 'grid',
    gridTemplateColumns: `1fr auto auto`,
    gridGap: pxToRem(10),
    alignItems: 'end',
    width: '100%',
    justifyItems: 'flex-end',
});

export const EnquiryAndSaveWrapper: StyleRule = () => ({
    justifyContent: 'normal',
    display: 'flex',
    gap: pxToRem(10),
});

export const HeaderButton: StyleRule = ({ theme }) => ({
    [`&.${buttonClassName}`]: {
        padding: pxArrayToRem([8, 20]),
        width: '100%',
        border: 'none',
        outline: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,

        [`& .${labelClassName}`]: {
            textTransform: 'uppercase',
            fontSize: pxToRem(15),
            lineHeight: pxToRem(32),
            ...theme.siteVariables.textStyles.Heading.Medium65,
            whiteSpace: 'nowrap',
        },

        // This fixes "Visit Website" button text jumping on Professional profiles
        '&:hover': {
            border: 'none',
        },
    },
});

export const VisitWebsiteButton: StyleRule = ({ isBMPreview }) => ({
    [`&.${buttonClassName}`]: {
        padding: pxArrayToRem([isBMPreview ? 24 : 25, 12]),
    },
});

export const ProfessionalHeaderContainer: StyleRule<{
    isSticky: boolean;
}> = ({ theme, isSticky }) => {
    return {
        [theme.screen.laptop]: {
            [`& > div`]: {
                boxShadow: isSticky
                    ? '0px 2px 24px 0px rgba(0, 0, 0, 0.10)'
                    : 'none',
            },
        },

        [theme.screen.clamped]: {
            background: theme.siteVariables.colorScheme.default?.background,
            top: 0,

            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            width: isSticky ? '100%' : pxToRem(1920),
            position: isSticky ? 'fixed' : 'relative',
            left: 0,

            boxShadow: isSticky
                ? '0px 2px 24px 0px rgba(0, 0, 0, 0.10)'
                : 'none',

            [`& > div`]: {
                width: pxToRem(1920),
                boxShadow: 'none',
            },
        },
    };
};

export const ProfessionalHeaderFixedWrapper: StyleRule = ({ theme }) => {
    return {
        background: theme.siteVariables.colorScheme.default?.background,

        [theme.screen.laptop]: {
            position: 'sticky',
            zIndex: theme.siteVariables.zIndexes.menuItem + 1,
            top: '-1px',
        },
    };
};

export const LinkButton: StyleRule = () => ({
    paddingBottom: pxToRem(4),
    textDecoration: 'underline',
    '& > span': {
        marginRight: pxToRem(10),
    },
});

export const SaveToDesign: StyleRule = () => ({
    display: 'flex',
});

export const SquareIconButton: StyleRule = ({ theme }) => ({
    [`&.${buttonClassName}`]: {
        outline: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,
        border: 'none',
        width: pxToRem(48),
        height: pxToRem(48),
    },
});

export const DesignBoardButton: StyleRule = () => ({
    cursor: 'grab',
});
